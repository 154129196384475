export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.log("errorHandler", error);
    console.log("errorHandler", instance);
    console.log("errorHandler", info);
    // handle error, e.g. report to a service
  };

  // Also possible
  nuxtApp.hook("vue:error", (error, instance, info) => {
    // handle error, e.g. report to a service
    console.log(error);
    console.log(instance);
    console.log(info);
  });
});
