import { default as codicebOAvxuSceGMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/codice.vue?macro=true";
import { default as index8sFrbKczxzMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/admin/index.vue?macro=true";
import { default as indexGcosWcpYAUMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/clienti/index.vue?macro=true";
import { default as creazione_disegnio23OfofFFrMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/creazione_disegni.vue?macro=true";
import { default as descrizionih0tVwuX21sMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/descrizioni.vue?macro=true";
import { default as indexmfgIDojppzMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/index.vue?macro=true";
import { default as numerazione_axYyVzBa1jLMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_a.vue?macro=true";
import { default as numerazione_bvTdjF9l9x0Meta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_b.vue?macro=true";
import { default as index3oBVGeNOx9Meta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/fornitori/index.vue?macro=true";
import { default as indexxuTIdN6wC3Meta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/generatore/index.vue?macro=true";
import { default as indexenWhGga9e8Meta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/index.vue?macro=true";
import { default as index2VWtcsYHsGMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/log/index.vue?macro=true";
import { default as createKSYszpBHFeMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/create.vue?macro=true";
import { default as indexnNziOdCksqMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/index.vue?macro=true";
import { default as indexhHP1SM1cp4Meta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/profilo/index.vue?macro=true";
import { default as index3AFuzoA6RrMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/index.vue?macro=true";
import { default as recupera_45passwordgJfbJfM3pUMeta } from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/recupera-password.vue?macro=true";
export default [
  {
    name: "codice",
    path: "/codice",
    meta: codicebOAvxuSceGMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/codice.vue")
  },
  {
    name: "dashboard-admin",
    path: "/dashboard/admin",
    meta: index8sFrbKczxzMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/admin/index.vue")
  },
  {
    name: "dashboard-clienti",
    path: "/dashboard/clienti",
    meta: indexGcosWcpYAUMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/clienti/index.vue")
  },
  {
    name: "dashboard-disnum-creazione_disegni",
    path: "/dashboard/disnum/creazione_disegni",
    meta: creazione_disegnio23OfofFFrMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/creazione_disegni.vue")
  },
  {
    name: "dashboard-disnum-descrizioni",
    path: "/dashboard/disnum/descrizioni",
    meta: descrizionih0tVwuX21sMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/descrizioni.vue")
  },
  {
    name: "dashboard-disnum",
    path: "/dashboard/disnum",
    meta: indexmfgIDojppzMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/index.vue")
  },
  {
    name: "dashboard-disnum-numerazione_a",
    path: "/dashboard/disnum/numerazione_a",
    meta: numerazione_axYyVzBa1jLMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_a.vue")
  },
  {
    name: "dashboard-disnum-numerazione_b",
    path: "/dashboard/disnum/numerazione_b",
    meta: numerazione_bvTdjF9l9x0Meta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_b.vue")
  },
  {
    name: "dashboard-fornitori",
    path: "/dashboard/fornitori",
    meta: index3oBVGeNOx9Meta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/fornitori/index.vue")
  },
  {
    name: "dashboard-generatore",
    path: "/dashboard/generatore",
    meta: indexxuTIdN6wC3Meta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/generatore/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexenWhGga9e8Meta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-log",
    path: "/dashboard/log",
    meta: index2VWtcsYHsGMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/log/index.vue")
  },
  {
    name: "dashboard-manualis-create",
    path: "/dashboard/manualis/create",
    meta: createKSYszpBHFeMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/create.vue")
  },
  {
    name: "dashboard-manualis",
    path: "/dashboard/manualis",
    meta: indexnNziOdCksqMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/index.vue")
  },
  {
    name: "dashboard-profilo",
    path: "/dashboard/profilo",
    meta: indexhHP1SM1cp4Meta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/profilo/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index3AFuzoA6RrMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/index.vue")
  },
  {
    name: "recupera-password",
    path: "/recupera-password",
    meta: recupera_45passwordgJfbJfM3pUMeta || {},
    component: () => import("C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/pages/recupera-password.vue")
  }
]