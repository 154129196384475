<template>
  <NuxtLayout name="login">
    <section>
      <div
        class="flex lg:flex-row flex-col gap-4 bg-surface-0 dark:bg-surface-900"
        style="height: 100vh"
        v-if="error?.statusCode === 404"
      >
        <div class="flex-1 flex items-center justify-center">
          <div class="p-6 pt-12 lg:p-12">
            <h1
              class="text-3xl lg:text-5xl font-bold text-surface-900 dark:text-surface-0 mb-4 lg:leading-normal text-center"
            >
              {{ error?.statusCode }}
            </h1>
            <h6
              class="font-bold text-surface-900 dark:text-surface-0 mb-4 lg:leading-normal text-center"
            >
              {{ error?.message }}
            </h6>
            <p
              class="text-surface-700 dark:text-surface-200 leading-normal mb-8 text-center lg:text-left"
            >
              La pagina che stai cercando non esiste.
            </p>
            <div
              class="flex items-center justify-center lg:justify-start gap-6"
            >
              <Button
                label="Torna indietro"
                type="button"
                outlined
                @click="indietro"
              />
            </div>
          </div>
        </div>
        <div class="flex-1 overflow-hidden">
          <img
            src="/progettazione-ponte-roma.webp"
            alt="Bt-Studio"
            class="h-full w-full object-cover lg:[clip-path:polygon(12%_0,100%_0%,100%_100%,0_100%)]"
          />
        </div>
      </div>
      <div
        class="flex lg:flex-row flex-col gap-4 bg-surface-0 dark:bg-surface-900"
        style="height: 100vh"
        v-if="error?.statusCode === 401"
      >
        <div class="flex-1 flex items-center justify-center">
          <div class="p-6 pt-12 lg:p-12">
            <h1
              class="text-3xl lg:text-5xl font-bold text-surface-900 dark:text-surface-0 mb-4 lg:leading-normal text-center"
            >
              {{ error?.statusCode }}
            </h1>
            <h6
              class="font-bold text-surface-900 dark:text-surface-0 mb-4 lg:leading-normal text-center"
            >
              {{ error?.message }}
            </h6>
            <p
              class="text-surface-700 dark:text-surface-200 leading-normal mb-8 text-center lg:text-left"
            >
              Non hai effettuato il login oppure le tue credenziali non sono
              valide.
            </p>
            <div
              class="flex items-center justify-center lg:justify-start gap-6"
            >
              <Button
                label="Torna indietro"
                type="button"
                outlined
                @click="logout"
              />
            </div>
          </div>
        </div>
        <div class="flex-1 overflow-hidden">
          <img
            src="/401.webp"
            alt="Bt-Studio"
            class="h-full w-full object-cover lg:[clip-path:polygon(12%_0,100%_0%,100%_100%,0_100%)]"
          />
        </div>
      </div>
    </section>
  </NuxtLayout>
</template>
<script setup>
defineOptions({
  inheritAttrs: false,
});
const error = useError();
const indietro = async () => {
  navigateTo("/dashboard");
};
const logout = async () => {
  const tokenCookie = useCookie("token");
  if (tokenCookie.value) {
    tokenCookie.value = null;
  }
  navigateTo("/");
};
</script>
