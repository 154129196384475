import KeyFilter from "primevue/keyfilter";
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("uppercase", (item) => {
    item.value = item.value.toUpperCase();
    //   return item;
  });
  nuxtApp.vueApp.directive("capitalize", (item) => {
    item.value =
      item.value.charAt(0).toUpperCase() + item.value.slice(1).toLowerCase();
    //  return item;
  });
  nuxtApp.vueApp.directive("keyfilter", KeyFilter);
});
