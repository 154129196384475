import revive_payload_client_4sVQNw7RlN from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_4oHW6JzpOW from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import primevue_plugin_egKpok8Auk from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import error_handler_VFH3VvK7yG from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/plugins/error-handler.js";
import toastservice_qSW7Kr0kwA from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/plugins/toastservice.js";
import utilita_RD0J6Kq2tL from "C:/Users/Administrator/Web/GESTIONALI/bt-studio-generatore-documenti/plugins/utilita.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  restore_state_client_4oHW6JzpOW,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  error_handler_VFH3VvK7yG,
  toastservice_qSW7Kr0kwA,
  utilita_RD0J6Kq2tL
]